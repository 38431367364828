import * as angular from 'angular';
import "../../components/blocworx/filters/objectOrderBy"

'use strict';

angular.module('MainCtrl')
    .controller('MainController', MainController);

MainController.$inject = [ '$http', 'Auth', '$auth', '$location', '$window', '$state',
    '$rootScope', '$scope', '$interval', 'Data', '$sce', 'Configuration', 'GridFactory',
    'Validator', 'datetime', 'LanguagesFactory', 'PageFactory', 'AppSettings', 'SCS' ];

function MainController($http, Auth, $auth, $location, $window, $state,
                        $rootScope, $scope, $interval, Data, $sce, Configuration, GridFactory,
                        Validator, datetime, LanguagesFactory, PageFactory, AppSettings, SCS) {

    let randomValue = Configuration.getRandomValue();

    let country = '';
    let flag = '';
    let vm = this;

    vm.$onInit = async function () {
        try {

            await vm.getGoogleAppSettings();

            vm.checkInIFrame();
            vm.pagefactory = PageFactory;
            vm.scanStationService = SCS;

            // starting the datetime configuration
            datetime.configuration();
            vm.url = window.location.href;
            vm.validator = Validator;
            vm.country = country;
            vm.flag = flag;
            vm.subDomain = Configuration.getSubDomain();
            $rootScope.$$listeners.$stateChangeStart = [];
            vm.dataType = $state.current.name;
            vm.sideBarStatus = 'closed';

            vm.appSettings = AppSettings;
            await vm.appSettings.load();

            // creating the auth
            vm.auth = Auth;
            await vm.auth.loadSuperUser();

            // loading the languages
            vm.languages = LanguagesFactory;

            // Those values are loaded once in the bootstrap.controller.ts
            vm.roleFailed = vm.auth.roleFailed();
            await vm.getUserRoles();

            vm.cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');
            vm.isCustomer = vm.auth.isCustomer();

            // TODO: we can remove the version after we remove those templates below //////////////////////////////////////////////////////////
            vm.version = Configuration.getVersion();
            vm.scanStationFormButtonsURL = 'views/front-end/scan-station-form-buttons.html?v=' + vm.version + 'rand=' + randomValue;
            vm.scanStationResultsDefaultURL = 'views/front-end/scan-station-results-default.html?v=' + vm.version + 'rand=' + randomValue;
            vm.getJobsByExternalKeyWordURL = 'views/admin/job-setup/get-jobs-by-external-keyword.html?v=' + vm.version + 'rand=' + randomValue;
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




        } catch ( e ) {
            console.log(e);
        } finally {
            $scope.$applyAsync();
        }
    }

    vm.getGoogleAppSettings = async function () {
        try {
            let settingsGoogle = await Auth.getSignInWithGoogleSettings();
            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));
            appSettingParsed = settingsGoogle;
            // this code is incorrect and is wiping all our app settings, commenting out for now.
            // TODO discuss with Luccas about this function
            //localStorage.setItem('appSettings', JSON.stringify(appSettingParsed))
        } catch ( e ) {
            console.log(e);
        }
    }

    vm.checkInIFrame = function () {
        try {

            if (window.self === window.top) {
                vm.inIframe = false;
                vm.isInIframe = '';
            } else {
                vm.inIframe = true;
                vm.isInIframe = 'is-in-iframe';
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.getUserRoles = async function () {
        try {

            vm.userRoles = await Auth.getUserRoles();

        } catch ( e ) {
            console.log(e);
        } finally {
            $scope.$applyAsync();
        }
    }

    /**
     * This is a two step authentication that happens every time a page loads or a link is clicked to change view.
     * First step is to verify the user is logged in, second step is they have the correct permissions for the page
     * they are trying to access.
     * This process is also done on the server side with its own data. This means that even if the user successfully
     * got to a page when they shouldn't have they will still not be able to retrieve or add any data.
     */
    vm.reloadPage = function () {
        try {
            $window.location.reload();
        } catch ( e ) {
            console.log(e);
        }
    }

    vm.publicLogin = async function () {
        try {
            let credentials = {
                email: vm.subDomain,
                password: 'public-pass'
            };

            await $auth.login(credentials);
            $window.location.reload();

        } catch ( e ) {
            console.log(e);
        }

    }

    vm.getAlternativeStateName = async function (stateName) {
        try {
            let data = await Data.getAlternativeStateName(stateName);
            vm.alternativeStateName = data.data.alternativeName;

        } catch ( e ) {
            console.log(e);
        }

    }

    /**
     * This is being used everywhere, we have on bootstrapController as well as
     * we need it, so I would say change this to a Service that can be used everywhere.
     *
     * TODO: Refactor this to a service.
     *
     * @param page
     */
    vm.getPageWords = async function (page) {
        try {
            let data = await Data.getPageWords(page)

            // we are loading this function several times from different areas of the page (different views)
            // Because of this we must accumulate the data so vm.pageWords isnt being overwritten
            vm.checkPageWords = localStorage.getItem('pageWords');
            if (vm.checkPageWords == null) {
                $window.localStorage.setItem('pageWords', JSON.stringify((data.data.pageWords)));
                vm.pageWords = data.data.pageWords;
            } else {
                // get the existing page words
                let pageWordsJSON = localStorage.getItem('pageWords');
                vm.pageWords = JSON.parse(pageWordsJSON);

                // add any new words that have appeared
                vm.newWords = data.data.pageWords;
                angular.forEach(vm.newWords, function (value, index) {
                    let addWord = true;
                    angular.forEach(vm.pageWords, function (pageWordsValue, pageWordsIndex) {
                        if (addWord == true) {
                            if (index == pageWordsIndex) {
                                addWord = false;
                                vm.pageWords[pageWordsIndex] = value;
                            }
                        }
                    });
                    if (addWord == true) {
                        vm.pageWords[index] = value;
                    }
                });
            }
            $window.localStorage.setItem('pageWords', JSON.stringify((vm.pageWords)));

            // this is being used only on: src/components/blocworx/components/navbar/template/toggle-desktop.html
            vm.languageSetting = data.data.languageSetting;

        } catch ( e ) {
            console.log(e);
        }

    }

    vm.loadCartolyticsCustomerShowOptions = async (data) => {
        await data.getGeneralAuditData();
        await data.getPageLinks();
        await vm.getCartolyticsCustomerShowOptions();
    }

    vm.getCartolyticsCustomerShowOptions = async function () {
        try {
            // Here we are fetching the list of items that can be shown for this customer (only if applicable, as most items will be shown for everyone)
            vm.cartolyticsCustomerShowOptions = localStorage.getItem('cartolyticsCustomerShowOptions');
            if (vm.cartolyticsCustomerShowOptions == null) {

                let data = await Data.getCartolyticsCustomerShowOptions();
                $window.localStorage.setItem('cartolyticsCustomerShowOptions', JSON.stringify((data.data.data)));
                vm.cartolyticsCustomerShowOptions = data.data.data;

            } else {
                // they were already retrieved so no need to call the API again
                let cartolyticsCustomerShowOptionsJSON = localStorage.getItem('cartolyticsCustomerShowOptions');
                vm.cartolyticsCustomerShowOptions = JSON.parse(cartolyticsCustomerShowOptionsJSON);
            }

        } catch ( e ) {
            console.log(e);
        }

    }

    vm.checkCartolyticsCustomerShowOption = function (showOption, defaultResult) {
        try {
            if (vm.cartolyticsCustomerShowOptions != null && vm.cartolyticsCustomerShowOptions.includes(showOption)) {
                return true;
            } else {
                return defaultResult;
            }

        } catch ( e ) {
            console.log(e);
        }

    }

    vm.updateHelpModeSetting = function () {
        try {
            if (vm.userSetting['help_mode'] === 'off') {
                vm.userSetting['help_mode'] = 'on';
            } else {
                vm.userSetting['help_mode'] = 'off';
            }

        } catch ( e ) {
            console.log(e);
        }

    }

    // Method that will turn on the grid
    vm.gridSemaphoreURL = function () {
        try {
            // TODO: check the whole logic with the grid!!
            return GridFactory.gridSemaphoreURL();
        } catch ( e ) {
            console.log(e);
        }

    }

    /**
     * Method that will check if it is on the view page, so for this form
     * must be disabled all elements if its on the view page.
     *
     * @param scanStation
     * @returns {boolean}
     */
    vm.isViewPage = function (scanStation) {
        try {
            return !scanStation.scanStationObj.isInEditMode && scanStation.showMoreSingleDataBox === true;
        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This will search for an Id on the arrayData and will
     * return if the arrayData[index].id == id.
     *
     * @param id
     * @param arrayData
     */
    vm.findDataById = (id, arrayData) => {

        let data = undefined;
        for (let index = 0; index < arrayData.length; index++) {
            if(arrayData[index].id == id){
                data = arrayData[index]
                break;
            }
        }
        return data;
    }

    /**
     * This will be updating an array, that
     * @param pages
     * @param section
     */
    vm.updateArrayOrder = (correctOrderArray, arrayWithData) => {

        let orderedByArray = [];
        for (let index = 0; index < correctOrderArray.length; index++) {

            // searching data on section.link of correctOrderArray[index].id
            let selectedSection = vm.findDataById(correctOrderArray[index].id, arrayWithData)

            if(selectedSection != undefined) {
                // update og the order id data from the correctOrderArray
                selectedSection.order_id = correctOrderArray[index].order_id;
                orderedByArray[correctOrderArray[index].order_id] = selectedSection
            }

        }

        // this will filter any value that will be empty.
        orderedByArray = orderedByArray.filter(element =>  Object.keys(element).length !== 0 ? true : false);

        return orderedByArray;
    }

    /**
     * This will increase the page order on the edit page.
     *
     * @param section
     * @param pageId
     * @param pageOrder
     * @param cartolyticsCustomerID
     */
    vm.increasePageOrder = async (section, pageId, pageOrder, cartolyticsCustomerID) => {
        try {
            let sectionId = section.id;
            let pages = await vm.pagefactory.increasePageOrder(sectionId, pageId, pageOrder, cartolyticsCustomerID);
            let orderedArray = vm.updateArrayOrder(pages,section.links);
            section.links = [] = orderedArray;
            $scope.$applyAsync();

        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This will decrease the page order on the edit page.
     * @param section
     * @param pageId
     * @param pageOrder
     * @param cartolyticsCustomerID
     */
    vm.decreasePageOrder = async (section, pageId, pageOrder, cartolyticsCustomerID) => {
        try {
            let sectionId = section.id;
            let pages = await vm.pagefactory.decreasePageOrder(sectionId, pageId, pageOrder, cartolyticsCustomerID);
            let orderedArray = vm.updateArrayOrder(pages,section.links);
            section.links = [] = orderedArray;
            $scope.$applyAsync();
        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This will replace all empty spaces to -.
     * @param name
     */
    vm.getFormattedNameClass = (name : string) => {
        return name.replace(/\s/g, '-')
    }

    /**
     *
     * @param data
     * @returns {Promise<void>}
     * This function initialises the homepage show options and page links
     */

    vm.initialiseHomePage = async (data) => {
        await data.getGeneralAuditData();
        await data.getPageLinks();
        await vm.getCartolyticsCustomerShowOptions();
        vm.showGeneralAudit = await vm.validateGeneralAudit(data);
        $scope.$applyAsync();

    }

    /**
     *
     * @param data
     * @returns {Promise<void>}
     * This function determines if we are going to show the general audit pop up or not
     * The criteria include the following:
     * 1. The user is an admin or has the role which says they are responsible for general audits
     * 2. The general audit setting is being used for this app
     * 3. The general audit has not been filled out today
     * If any of the above don't apply we do not show the pop up
     */
    vm.validateGeneralAudit = async (data) => {

        if( data.generalAuditData.length == 0
            && vm.cartolyticsCustomerShowOptions.includes('general_audit_pop_up')
            && (
                vm.userRoles.includes('general-audit')
                || vm.userRoles.includes('admin-front-end')
                || vm.userRoles.includes('admin'))
            ){
            return true;
        } else {
            return false;
        }

    }
}

export default MainController;
